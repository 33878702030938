.subtitle1 {
	bottom: 24px;
	right: 0;
	z-index: -1;
	-webkit-text-stroke: 1px #001fc5;
	text-transform: uppercase;
}
.subtitle2 {
	top: 0;
	left: 40%;
	transform: translateX(-40%);
	z-index: -1;
	-webkit-text-stroke: 1px #001fc5;
	text-transform: uppercase;
}
.subtitle3 {
	right: 60px;
	top: 50%;
	transform: translateY(-50%);
	z-index: -1;
	-webkit-text-stroke: 1px #001fc5;
	text-transform: uppercase;
}
.subtitle4 {
	bottom: 0;
	left: 40%;
	transform: translateX(-40%);
	z-index: -1;
	-webkit-text-stroke: 1px #001fc5;
	text-transform: uppercase;
}
.services-icon {
	margin-bottom: 8px;
}
.choose-no {
	position: absolute;
	left: 0px;
	top: -10px;
	letter-spacing: 8px;
	opacity: 0.05;
}
@media (max-width: 899.5px) {
	.order-sm-2 {
		order: 2;
	}
	.order-sm-1 {
		order: 1;
	}
	.subtitle1,
	.subtitle2,
	.subtitle3,
	.subtitle4 {
		display: none;
	}
}
@media (max-width: 1024px) {
	.banner {
		height: inherit !important;
	}
}

button.PrivatePickersYear-yearButton {
	font-size: 0.857143rem;
	line-height: 1.66;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	padding: 0px;
	background-color: rgb(255, 255, 255);
	color: rgba(0, 0, 0, 0.87);
	margin: 0px 2px;
	opacity: 1;
	letter-spacing: unset;
	font-family: Sen, sans-serif;
}

@media only screen and (min-width: 1600px) {
	.banner h1 {
		font-size: 50px;
	}
	.banner p {
		font-size: 28px;
	}
	.banner button {
		font-size: 18px;
		padding: 12px 25px;
	}
	.banner {
		height: calc(100vh - 140px) !important;
	}
}