/* label.css-1p9jm22-MuiFormLabel-root-MuiInputLabel-root,
div.css-1nhhrsk-MuiInputBase-root-MuiOutlinedInput-root,
.css-1f4arra,
label.css-18ipvyh-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 16px;
}
span.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    color: #001FC5;
}
.MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #001FC5;
}
.css-vnkopk-MuiStepLabel-iconContainer svg {
    color: rgba(99, 119, 220, 0.3);
}
.MuiButtonBase-root-MuiMenuItem-root {
    font-size: 16px;
}
span.MuiStepConnector-line {
    border-color: rgba(99, 119, 220, 0.3);
} */
.MuiFormLabel-root,
.MuiInputBase-formControl,
.MuiFormControlLabel-label {
    font-size: 16px !important;
}
span .MuiStepIcon-root,
span .MuiStepLabel-label {
    color: rgba(99, 119, 220, 0.75);
}
span.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    border-top-width: 3px;
    border-color: rgba(99, 119, 220, 0.75);
}
span.MuiStepLabel-label.Mui-active {
    color: #001FC5;
}
.MuiCheckbox-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.MuiFormControlLabel-root {
    align-items: flex-start !important;
}
.d-none{
    display: none;
}
.MuiDialogContent-root {
    padding: 16px;
}
.MuiDialogActions-root .MuiBox-root {
    width: 100%;
    justify-content: space-between !important;
}
.BackButton.MuiButton-root.Mui-disabled {
    opacity: 0;
}