Button.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9;
    background: #BF1E29;
    color: white;
    cursor: pointer;
    min-width: 40px;
    height: 40px;
    padding: 0;
}
.back-to-top:hover {
    background: #001FC5;
}
.footer a {
    color: inherit;
    text-decoration: none;
}
.footer a:hover {
    color: #001fc5;
    text-decoration: none;
}