ol {
    
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 34px;
    margin-bottom: 8px;
}
ol li {  
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0;
    color: #444444;
    font-family: Sen,sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
}