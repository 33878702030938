.MuiDrawer-paper {
    width: 80vw;
}
.link-item {
    color: #444444;
    cursor: pointer;
    padding: 6px 18px;
    border-radius: 4px;
    margin-left: 6px;
}
a.link-item {
    text-decoration: none;
}
.link-item:hover,
.link-item.active {
    color: #001fc5;
    background-color: #eaeffc;
}
.m-link-item {
    display: block;
    margin-right: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
}
@media (min-width: 990px) and (max-width: 1024px) {
    .link-item {
        padding: 6px 12px;
    }
}
@media (max-width: 990px) {
    .logo {
        max-width: 160px;
    }
}